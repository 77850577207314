.upload-component {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 500px;
    margin: 50px auto;
    margin-top: 150px;
    margin-bottom: 330px;
}

.upload-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.file-input-label {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.file-input {
    display: none;
}

.upload-button {
    padding: 10px 20px;
    background-color: Black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.upload-button:hover {
    background-color: #0056b3;
}

.image-preview {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 15px;
}

.description-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
