/* Base styles for the navbar */
.navbar,
.navbar-mobile {
  background-color: #ffffff; /* Dark background for a sleek look */
  color: white;
  padding: 0 20px;
  z-index: 1000; /* Ensure it's above other content */
  position: fixed;
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  width: 100%; /* Full width */
  display: flex; /* Flex layout for horizontal alignment */
  align-items: center; /* Vertical alignment */
  height: 70px; /* Set a fixed height for the navbar */
  justify-content: space-between;
}

/* Desktop Styles */
.navbar {
  justify-content: space-between; /* Space between logo and links */
}

.nav-links {
  display: flex; /* Ensures horizontal layout for icons */
}

.nav-icon,
.nav-icon button {
  background: none; /* Removes default button background */
  border: none; /* Removes default button border */
  color: rgb(0, 0, 0);
  text-decoration: none;
  margin-left: 20px; /* Adjusted spacing between icons */
  margin-right: 25px;
  padding: 5px; /* Adjusted padding for a slimmer look */
  border-radius: 5px;
  transition: background-color 0.3s;
  cursor: pointer; /* Ensures items are displayed in a row */
  align-items: stretch; /* Aligns items horizontally */
  font-size: inherit; /* Ensures font size consistency */
  outline: none; /* Removes the default focus outline */
}

.nav-icon i {
  font-size: 20px; /* Adjusted icon size */
}

.nav-icon:hover,
.nav-icon button:hover {
  background-color: #555;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }

  .nav-links {
    width: 100%; /* Full width for icons */
    justify-content: center; /* Center icons horizontally */
  }
}

.nav-logo {
  display: flex; /* Use flexbox to align items horizontally */
  align-items: center; /* Align items vertically in the center */
  text-decoration: none;
  padding: 5px 0; /* Adjust padding as needed */
}

.nav-logo-img {
  height: 40px; /* Adjust as needed */
  width: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Space after logo */
}

.nav-logo-text {
  font-size: 35px; /* Adjust font size as needed */
  color: #000000; /* Text color */
}
