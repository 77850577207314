.portfolio-container {
    padding: 20px;
    display: flex;
    flex-wrap: wrap; /* Allow multiple items per line */
    justify-content: center; /* Center items horizontally */
    gap: 20px; /* Space between items */
    margin-top: 100px;
    margin-bottom: 25px;
  }
  
  .portfolio-item {
    width: 30%; /* Adjust width for smaller items */
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .portfolio-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .item-description, .edit-input {
    font-size: 0.9rem; /* Smaller font size */
    color: #333;
    margin: 0 auto;
    max-width: 90%; /* Adjust text width */
  }
  
  .upload-date {
    color: #666;
    font-size: 0.7rem; /* Smaller font size */
    margin-top: 5px;
  }
  
  .delete-button, .edit-button {
    padding: 5px 10px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s;
  }
  
  .delete-button:hover, .edit-button:hover {
    background-color: darkred;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .portfolio-container {
      flex-direction: column;
      align-items: center;
    }
  
    .portfolio-item {
      width: 80%; /* Larger width for mobile devices */
    }
  }
  