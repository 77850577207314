/* SignUp.css */

.sign-up-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.sign-up-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.sign-up-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.sign-up-button,
.google-sign-up-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #28a745; /* Different color for sign up */
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-bottom: 10px;
}

.sign-up-button:hover,
.google-sign-up-button:hover {
  background-color: #218838; /* Darker shade for hover effect */
}

.sign-up-link {
  margin-top: 15px;
  display: block;
  color: #007bff;
  text-decoration: none;
}

.sign-up-link:hover {
  text-decoration: underline;
}

.sign-up-logo {
    width: 250px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    display: block;
    margin: 0 auto 20px; /* Center the image and add some margin */
  }