/* SignIn.css */

.sign-in-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.sign-in-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.sign-in-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.sign-in-button,
.google-sign-in-button {
  padding: 12px 20px; /* Slightly larger padding for better button size */
  border: none;
  border-radius: 5px; /* Rounded corners */
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s; /* Added transform for hover effect */
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); /* Subtle box shadow for depth */
}

.sign-in-button:hover,
.google-sign-in-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px); /* Slight lift on hover */
}

.sign-in-link {
  margin-top: 15px;
  display: block;
  color: #007bff;
  text-decoration: none;
}

.sign-in-link:hover {
  text-decoration: underline;
}

.sign-in-error {
  color: red;
  margin-bottom: 10px;
}

.sign-up-logo {
  width: 250px;
  height: auto;
  display: block;
  margin: 0 auto 20px;
}

.google-sign-in-image {
    cursor: pointer;
    height: 40px; /* Set the height to match the email sign-in button */
    width: auto; /* Maintain aspect ratio */
    margin-top: 20px;
    border-radius: 5px; /* To match the email sign-in button */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); /* To match the email sign-in button */
    transition: transform 0.2s; 
  }
  
  .google-sign-in-image:hover {
    transform: translateY(-2px); 
  }
  
  .sign-in-or {
    margin-bottom: 10px 0; 
    font-size: 20px;
    color: #000000;
  }
  