.trade-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f0f0f0; /* Light background for contrast */
}

.trade-item {
    width: 80%;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between; /* Space between image and text */
    background: white; /* White background for items */
    transition: transform 0.2s, box-shadow 0.2s; /* Smooth hover effect */
}

.trade-item:hover {
    transform: translateY(-5px); /* Slight lift on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Deeper shadow on hover */
}

.trade-image {
    max-width: 150px;
    height: auto;
    margin: 10px; /* Margin around image */
    border-radius: 5px;
    object-fit: cover; /* Ensure aspect ratio is maintained */
}

.item-info {
    flex-grow: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space between text and button */
}

.item-info p {
    margin: 5px 0;
    color: #333;
    font-size: 0.9rem;
}

.trade-button {
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    font-size: 1rem; /* Larger font size */
    align-self: flex-end; /* Align button to the right */
}

.trade-button:hover {
    background-color: #0056b3;
    transform: scale(1.05); /* Slightly enlarge button on hover */
}

.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .enlarged-image {
    max-width: 80%;
    max-height: 80%;
    border-radius: 10px;
  }
  

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .trade-item {
        flex-direction: column;
        align-items: stretch;
    }

    .trade-image {
        width: 100%;
        margin-bottom: 10px;
    }

    .item-info {
        text-align: center;
    }

    .trade-button {
        align-self: center; /* Center button on smaller screens */
    }
}
