.content {
  display: flex;
  width: 100%;
  scroll-snap-type: y mandatory;
}

.image-container, .text-container {
  width: 50%;
  height: 400vh;
  overflow-y: scroll;
}

.image-section {
  height: 100vh;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
}

.display-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  color: #333;
  position: sticky;
  top: 0;
  overflow-y: hidden;
}

.text-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 5%;
  animation: slideIn 1s ease forwards;
  opacity: 0;
}

.text-section h1 {
  font-size: 3rem;
  color: #000000;
  margin-bottom: 10px;
}

.text-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.text-section p {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 80%;
}

@keyframes slideIn {
  from {
    transform: translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
