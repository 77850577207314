.footer {
    background-color: white;
    padding: 20px;
    text-align: center;
    border-top: 1px solid #ddd; /* subtle top border */
    width: 100%; /* Ensure full width */
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px; /* Set a max-width for larger screens */
    margin: 0 auto; /* Center the content */
  }
  
  .footer p {
    margin: 0;
    color: #333;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
  }
  
  .social-icon {
    color: #333;
    margin: 0 10px;
    font-size: 24px;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .social-icon:hover {
    color: #007bff; /* Change color on hover */
  }
  