.search-bar {
    margin-bottom: 20px;
  }
  
  .search-input {
    width: 80%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  